<!--
 * @Descripttion:
 * @Author: 宋爽爽
 * @Date: 2020-03-19 17:43:38
 * @LastEditTime: 2020-06-05 14:07:31
 -->
<template>
  <div class="jepass-servicePhone">
    <el-dialog
      custom-class="phoneDialog"
      :visible.sync="phoneDialogVisible"
      append-to-body
      destroy-on-close
      @open="dialogOpened"
    >
      <div
        slot="title"
        class="serviceTitle"
      >
        <span>电话咨询</span>
      </div>
      <div
        v-for="item in persons"
        :key="item.order"
        class="smlist"
      >
        <i
          :class="hzhbStatus && 'font-size-24'"
          class="el-icon-phone-outline"
        />
        <span
          :class="hzhbStatus && 'font-size-24'"
          class="margin-left-5 font-weight-600 font-size-24"
        >{{ item.name }}:</span>
        <span
          class="margin-left-5"
          :class="hzhbStatus && 'font-size-24'"
          style="color: #F8A634"
        >{{ item.phone }}</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSalesMan } from '@/pages/detail/actions/actions';

export default {
  name: 'PhoneDialog',
  data() {
    return {
      phoneDialogVisible: false,
      persons: [],
      hzhbStatus: false, // 是否是合作伙伴的显示
    };
  },
  created() {
    this.getPersons();
  },
  methods: {
    dialogOpened() {
      if (this.persons.length == 0) {
        this.getPersons();
      } else {
        this.persons.sort(() => Math.random() - 0.5);
        this.persons.sort(() => Math.random() - 0.5);
      }
    },
    // 获取销售人员集合
    getPersons() {
      if (window.__detail && window.__detail.PRODUCT_CSXS) {
        this.hzhbStatus = true;
        const detail = {
          name: window.__detail.PRODUCT_CSXS,
          phone: window.__detail.PRODUCT_CSDH,
        };
        this.persons = [];
        this.persons.push(detail);
        return;
      }
      this.hzhbStatus = false;
      getSalesMan().then((result) => {
        if (!result.success) {
          return this.$message({
            type: 'error',
            message: result.message || '获取数据失败！',
          });
        }
        const arr = result.obj || [];
        arr.forEach((item, index) => {
          item.name = item.values && item.values.XSRYB_XM;
          item.phone = item.values && item.values.XSRYB_LXDH;
          item.email = item.values && item.values.XSRYB_LXYX;
          item.order = index + 1;
        });
        // 随机排序
        arr.sort(() => Math.random() - 0.5);
        arr.sort(() => Math.random() - 0.5); // 一次有可能不变 所以两次  保证每次都会变化
        this.persons = arr;
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../index/style/shop/util";
</style>
<style lang="less">
.phoneDialog {
  width: 540px !important;
  border-radius: 20px !important;
  padding: 0!important;
  .el-dialog__header {
    padding: 0!important;
  }
  .serviceTitle {
    padding: 20px 20px;
    border-radius: 10px;
    color: #ffffff;
    height: 128px;
    background: url('../assets/images/zs/service.png') !important;
    background-size: 100% auto !important;
    background-repeat: no-repeat !important;
  }
  .el-dialog__headerbtn .el-icon-close{
    font-size: 25px;
    color: #ffffff!important;
  }
  .el-dialog__title {
    font-size: 22px !important;
    font-weight: 600 !important;
  }
  .el-dialog__body {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 400;
    color: rgba(63, 63, 63, 1);
    padding-top: 10px;
    padding-bottom: 100px;
    .smlist {
      padding: 10px 0;
      text-align: center;
    }
  }
}
</style>
