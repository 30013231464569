<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-04-08 19:14:01
 * @LastEditTime: 2020-05-07 13:43:28
 -->
<template>
  <el-card class="box-card">
    <div slot="header">
      <span class="title">建议购买官方VIP服务</span>
      <el-button
        type="text"
        style="margin-left: 10px;"
        @click="openService"
      >
        服务详情
      </el-button>
    </div>
    <div class="opt">
      <div class="mlabel">
        官方VIP服务
      </div>
      <Service
        :is-at-top="1"
      />
    </div>
  </el-card>
</template>

<script>
import { VIP_CODE } from '@/pages/detail/actions/utilVarlable';
import Service from './service';

export default {
  name: 'VipServe',
  components: {
    Service,
  },
  data() {
    return {
    };
  },
  methods: {
    // 点击服务详情
    openService() {
      window.open(`/shop/${VIP_CODE.toLowerCase()}.html`);
    },
  },
};
</script>

<style lang="less">
.box-card {
  margin: 30px 0 150px;
  .opt {
    padding: 10px;
    .mlabel {
      padding-bottom: 15px;
      font-size: 20px;
      font-weight: 400;
      color: rgba(63, 63, 63, 1);
    }
  }
}
</style>
