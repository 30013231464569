<template>
  <div class="je-pasySuccess">
    <div class="box_top">
      <Header />
    </div>
    <div
      :class="productType == 'SYZS' && 'center-container-mt'"
      class="center-container"
    >
      <img
        src="../../../../assets/images/success.png"
        alt=""
      > <br><br>
      <span class="font-size-22 color-F9524F">恭喜你购买成功！</span>
      <div
        v-if="productType == 'SYZS'"
        class="sm"
      >
        销售人员会在15分钟内联系您并审核版权信息，请留意审核通过短信通知。您可去“
        <span
          @click="openOrders"
          class="buid"
        >
          我的订单
        </span>
        ”中查看订单并下载证书。
      </div>
      <div
        v-else
        class="sm"
      >
        您可去“
        <span
          @click="openOrders"
          class="buid"
        >
          我的订单
        </span>
        ”中查看订单<span v-if="productType == 'ZQZJ'">。并下载钥匙。</span>
      </div>
    </div>
    <div
      v-if="productType == 'SYZS'"
      class="vip-container"
    >
      <VipServe />
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header';
import VipServe from '../../../detail/components/vipService';

export default {
  name: 'PaySuccess',
  components: {
    VipServe,
    Header,
  },
  data() {
    return {
      productType: 'SYZS',
    };
  },
  watch: {
    $route() {
      this.$router.go(0);
    },
  },
  created() {
    this.productType = this.$store.state.productType;
  },
  methods: {
    // 打开已购列表
    openOrders() {
      this.$router.push({
        path: '/alreadyList',
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../style/shop/util.less";
</style>
<style lang="less">
.je-pasySuccess {
  position: relative;
    .el-backtop {
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      z-index: 10;
      border-radius: 4px;
      width: 52px;
      height: 52px;
      right: 2% !important;
      bottom: 43px !important;
      font-size: 24px;
      color: #ff3041;

      &:hover {
        background: #ff3041;

        > i {
          color: #ffffff;
        }
      }
    }

    .box_top {
      width: 100%;
      height: 80px;
      background: linear-gradient(270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%);
    }
  .center-container-mt {
    margin-top: 50px!important;
  }
  .center-container {
    width: 1250px;
    margin: 150px auto 0 auto;
    text-align: center;
    margin-bottom: 60px;
    font-size: 18px;
    color: #3f3f3f;
    padding-top: 15px;
    img {
      font-size: 0;
    }
    .buid {
      color: #376BD0;
      cursor: pointer;
      &:hover {
        color: #409EFF;
      }
    }
    .sm {
      margin-top: 20px;
    }
  }
  .vip-container {
    width: 1250px;
    margin: 0 auto;
    .el-card {
      margin-bottom: 0;
    }
  }
}
</style>
