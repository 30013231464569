/*
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-04-15 14:56:19
 * @LastEditTime: 2020-04-15 15:01:36
 */
// 打赏的CODE
export const DS_CODE = 'JEPAAS-RGFW-0008';
// 打赏的CODE
export const VIP_CODE = 'JEPAAS-RGFW-0001';
