<template>
  <div class="vip-service">
    <el-radio-group
      v-model="defaultSelected"
      size="medium"
      @change="radioChange"
    >
      <el-row
        typ="flex"
        justify="space-between"
        style="width: 100%;"
        :gutter="10"
      >
        <el-col
          v-for="(item, index) in radioArrData"
          :key="index"
          :span="6"
        >
          <el-radio-button :label="item">
            {{ item.text }}
          </el-radio-button>
        </el-col>
      </el-row>
    </el-radio-group>
    <!-- <div
      v-if="isAtTop == 1"
      class="mtip"
    >
      福利优惠：{{ price || 0 }}元/月
    </div> -->

    <div class="btns">
      <span class="prc">
        ￥{{ defaultSelected.money.toFixed(2) || '无' }}
        <!-- <span
          v-if="isAtTop == 2"
          class="mtip"
        >福利优惠：{{ price || 0 }}元/月</span> -->
      </span>
      <span class="btn">
        <PayButtons
          :lines="'one'"
          :product-type="'VIP'"
          :form-data="choosedPrice"
          @openPhoneDialog="openPhone"
        />
      </span>
    </div>
    <PhoneDialog
      ref="phoneDialog"
    />
  </div>
</template>
<script>
import { VIP_CODE } from '@/pages/detail/actions/utilVarlable';
// import { getPageUrl } from '@/util/util';
import {
  getResourceData, getProductList4order,
} from '@/pages/detail/actions/actions';
import PayButtons from './payButtons';
import PhoneDialog from './phone';

export default {
  name: 'Service',
  components: {
    PayButtons, PhoneDialog,
  },
  props: {
    isAtTop: {
      type: Number,
      default: 0, // 0是不显示 1 是显示在金额上面 2是显示在金额后面
    },
    price: {
      type: Number,
      default: 0,
    },
    radioRules: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      radioArrData: [], // 当前价格体系
      defaultSelected: {},
      choosedPrice: {},
    };
  },
  created() {
    this.getPayBtns(); // 获取支付按钮
  },
  mounted() {
  },
  methods: {
    // 获取组件配置信息
    getConfig() {
      // this.choosedPrice.payBtns = this.mock.payBtns;
      const params = {
        productCode: VIP_CODE,
        type: 'labour',
      };
      getResourceData(params).then((res) => {
        const arr = [];
        if (res.success) {
          res.obj.forEach((money) => {
            const objArr = {
              text: money.values.RGFW_GGSM,
              money: money.values.RGFW_JG,
              shopId: money.values.JEPAAS_RGFW_ID || '',
            };
            arr.push(objArr);
          });
          this.radioArrData = arr;
          this.defaultSelected = this.radioArrData[0];
          this.choosedPrice.shopId = this.defaultSelected.shopId;
          this.choosedPrice.price = this.defaultSelected.money;
        }
      });
    },
    // 获取支付方式
    getPayBtns() {
      getProductList4order({ productCodes: VIP_CODE }).then((res) => {
        if (res.success) {
          const info = res.obj[0];
          this.choosedPrice.payBtns = info.productInfo.PRODUCT_ZFFS_CODE;
          this.choosedPrice.shopCode = VIP_CODE;
          this.getConfig();
        }
      });
    },
    // 当前商品信息
    radioChange(val) {
      this.defaultSelected = val;
      this.choosedPrice.price = val.money;
      this.choosedPrice.shopId = val.shopId;
    },
    // 打开联系电话弹窗
    openPhone() {
      this.$refs.phoneDialog.phoneDialogVisible = true;
    },
  },
};
</script>

<style lang="less">
  @import "../../index/style/shop/util";
.vip-service {
  text-align: center;
  .mtip {
    font-size: 18px;
    font-weight: 400;
    color: rgba(169, 171, 169, 1);
    padding-top: 10px;
    text-align: left;
  }
  .btns {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 10px;
    .prc {
      font-size: 44px;
      font-weight: 500;
      color: rgba(250, 87, 80, 1);
    }
    .btn {
      flex: 1;
      text-align: right;
    }
  }
  .el-radio-group {
    width: 100%;
    .el-radio-button {
      width: 100%;
      &:last-child {
        margin-right: 0;
      }
    }
    .el-radio-button__inner {
      border: none;
      background: #F5F5F5;
      height: 44px;
      line-height: 25px;
      border: 0!important;
      /*border: 1px solid rgba(247, 74, 73, 1);*/
      /*border-left: 1px solid rgba(247, 74, 73, 1);*/
      width: 100%;
      box-sizing: border-box;
      border-radius: 3px;
      font-weight: 500;
      margin-right: 40px;
    }
    .el-radio-button:first-child .el-radio-button__inner {
      border-radius: 3px;
      /*border-left: 1px solid rgba(247, 74, 73, 1);*/
    }
    .el-radio-button:last-child .el-radio-button__inner {
      margin-right: 0;
      border-radius: 3px;
    }
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      border: 0;
      background: linear-gradient(
        270deg,
        rgba(253, 147, 81, 1) 0%,
        rgba(247, 73, 73, 1) 100%
      );
      box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
      /*border-color: rgba(253, 147, 81, 1);*/
    }
  }
}
</style>
